

























































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { getLinks } from "@/core/utils/getLinks";
import { Component, Watch } from "vue-property-decorator";
import LinkPreview from "@/components/common/LinkPreview.vue";

const placeholderImage =
  "https://www.geographicexperiences.com/wp-content/uploads/revslider/home5/placeholder-1200x500.png";
@Component({ components: { LinkPreview } })
export default class AddArticleButton extends Vue {
  @Getter("news/addArticleLoading") addArticleLoading!: boolean;
  @Action("news/addArticle") addArticleAction!: Function;

  body = "";
  title = "";
  addDialog = false;
  files: File[] = [];
  links: string[] = [];
  hasBannerImage = false;
  bannerImagePreview: any = "";
  bannerImage: File | null = null;

  previewLinks() {
    this.links = getLinks(this.body);
  }

  textRules = [
    (v: string) => {
      if (v == null) return this.$t("forms.reqfield").toString();
      if (v.trim().length == 0) return this.$t("forms.reqfield").toString();
      else return true;
    },
  ];

  get canSubmit() {
    return this.title.length > 0 && this.body.length > 0;
  }

  handleBannerImage(file: File) {
    this.bannerImage = file;
    if (!file) this.bannerImagePreview = placeholderImage;
    else {
      let reader = new FileReader();
      reader.onload = () => (this.bannerImagePreview = reader.result);
      reader.readAsDataURL(this.bannerImage);
    }
  }
  addArticle() {
    if (this.addArticleLoading) return;
    if (
      this.title.length == 0 ||
      this.body.length == 0 ||
      (this.hasBannerImage && this.bannerImage == null)
    )
      return;

    const data = new FormData();
    data.append("title", this.title.trim());
    data.append("body", this.body.trim());
    data.append("bannerImage", this.bannerImage!);
    this.files.forEach(file => data.append("files", file));
    this.addArticleAction(data);
    this.addDialog = false;
  }

  @Watch("addDialog")
  toggleDialog() {
    this.title = "";
    this.body = "";
    this.hasBannerImage = false;
    this.bannerImage = null;
    this.bannerImagePreview = placeholderImage;
    this.files = [];
  }
}
